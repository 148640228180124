import React from "react";
import Page from "../containers/page";
import { StaticImage } from "gatsby-plugin-image"

const About = () => {
  return (
    <Page pageTitle="About Us" description="">
      <section className="py-24">
        <div className="flex justify-center items-center">
          <div className="w-full md:w-2/3 px-6">
            <h1>About Dawn Moody</h1>
            <StaticImage src="../images/dawn2.jpg" alt="District Judge Dawn Moody " className="block m-auto md:float-right md:mt-4 md:ml-6 bg-light_gray border border-black border-opacity-25" objectFit="contain" width={350} />
            <p className="mt-6">Dawn Moody was born in Riverside, California.  She lived in California, Florida, and New Mexico before finally moving to Tulsa in 1982.  She graduated from Cascia Hall Preparatory School, and then attended the University of Tulsa for both her undergraduate and law degrees.</p>

            <p>She started her legal career as an intern for the Public Defender’s office.  Upon graduating from law school, Dawn was hired on as a felony trial attorney with the Public Defender’s office.  While she was working at the PD’s office, she met and began dating her husband, Chad Moody – an Assistant District attorney in Tulsa County. As an Assistant Public Defender, she was initially assigned to a juvenile delinquent docket and then spent a year on a felony trial docket. </p>

            <p>Dawn left the PD’s office in November 2002, and began practicing primarily criminal defense with her father, Dan Kramer, whom she attributes to teaching her everything she knows, but certainly NOT everything he knows.  While in private practice she had two children, a son Chase and a daughter Brynn, born 15 months apart. </p>

            <p>In January 2007, Dawn was hired on as a Judicial Referee.  Her assignments included parenting plan conferences, drug court initial appearances, DUI court reviews and juror financial hardship hearings. </p>

            <p>In August 2009, Presiding Judge Tom Thornbrugh received special permission from the Supreme Court of Oklahoma to promote her to Special Judge.  Her assignments included a Family and Domestic docket, Drug, DUI and Mental Health Court, arraignments, misdemeanor domestic violence docket and the cost docket.</p>

            <p>In 2018, she was elected to the District Bench, where she has overseen a criminal docket.  She was Chief of the Criminal Division in 2018 and again in 2021.</p>

            <p>In her free time, she enjoys cooking and/or baking. Spending time with her husband Chad, their daughter Brynn who is a senior in high school, their black lab Maggie,  as well as going to Stillwater as often as she can to see her son Chase. </p>
          </div>
        </div>
      </section >
    </Page >
  );
}

export default About;